@font-face {
  font-family: 'Druk Wide';
  src: url('DrukWide-Bold.eot');
  src: url('DrukWide-Bold.eot?#iefix') format('embedded-opentype'), url('DrukWide-Bold.woff2') format('woff2'),
    url('DrukWide-Bold.woff') format('woff'), url('DrukWide-Bold.ttf') format('truetype'),
    url('DrukWide-Bold.svg#DrukWide-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
